<template>
    <div>
        <Header />

        <div class="mt-4 projects">
            <div class="pt-4">
                <h2 class="h1 mb-4 u-text-primary">Portfolio</h2>
                <Project
                    class="mb-4"
                    v-bind="project"
                    v-for="(project, index) in projects"
                    :key="`project-${index}`"
                    :isFirst="index === 0"
                />
            </div>
            <Contact />
        </div>
    </div>
</template>

<script>
import Project from '../components/Project'
import Header from '../components/Header'
import Contact from "../components/Contact";

export default {
    name: 'Home',

    components: { Contact, Header, Project },

    computed: {
        projects () {
            return this.$store.state.projects
        },
    },
}
</script>
