<template>
    <div class="mb-3 fact">
        <h2>{{ title }}</h2>
        <p>
            <b-icon
                class="mr-2"
                font-scale="1.2"
                shift-v="-1"
                animation="fade"
                :icon="icon"
                :variant="variant"
            />{{ text }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'Fact',

    props: {
        title: { type: String, required: true },
        text: { type: String, required: true },
        variant: { type: String, required: true },
        icon: { type: String, required: true },
    },
}
</script>
