<template>
    <dl :class="['row pt-4', isFirst ? '' : 'u-separator']" data-aos="fade-left" data-aos-duration="2000">
        <dt class="col-12 col-sm-3 projects__title">
            <h3 class="projects__headline d-inline-block">{{ title }}</h3>
            <a v-if="link" :href="link" rel="noopener" :title="`Gehe zum Projekt ${title}`"><b-icon icon="link" font-scale="1.3" /></a>
        </dt>

        <dd class="col-12 col-sm-8 m-0" v-html="text" />

        <dd v-if="image" class="col-12 col-sm-8 offset-sm-3 mt-4">
            <img
                class="highlight"
                :srcset="`${imageSourceSmall} 545w, ${imageSource} 730w`"
                sizes="(max-width: 992px) 160px, 730px"
                :src="imageSource"
                alt=""
                title=""
                width="100%"
                height="auto"
            />
        </dd>
    </dl>
</template>

<script>
export default {
    name: 'Project',

    props: {
        title: { type: String, required: true },
        text: { type: String, required: true },
        link: { type: String, default: null },
        image: { type: String, default: null },
        isFirst: { type: Boolean, default: false },
    },

    computed: {
        imageSource () {
            return require(`../assets/projects/${this.image}`)
        },

        imageSourceSmall () {
            return require(`../assets/projects/${this.image}_w545.jpg`)
        },
    },
}
</script>
