<template>
    <div class="image-stripe flex">
        <div>
            <img src="../assets/nick.jpg" alt="Autor Nick Böcker: Rote Haare unter einer schwarzen Beanie; ein breites Lächeln auf den Lippen; schwarzer Pulli" title="" width="100%" height="auto" />
        </div>
        <div class="logo-wrapper bv-d-sm-down-none">
            <img src="../assets/nhb_goat.gif" alt="Logo von Nick hat Böcker im Pixelstil: Ein Ziegenbock, darunter die Abkürzung NHB" title="" height="60%" width="auto" />
        </div>
        <div>
            <img src="../assets/nala.jpg" alt="Office Dog Nala: Ein Dackel der die Zunge rausstreckt" title="" width="100%" height="auto" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImageStripe',
}
</script>

<style lang="scss">
    .image-stripe {
        & > div {
            border: $spacing-unit / 2 solid $primary;
            flex: 1 1 0;
        }
    }

    .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
